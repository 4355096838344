import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { AuthService } from "./auth.service";
import { BrandService } from "../shared/services/brand.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private brandService: BrandService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.brandService.brandSettings?.id) {
      request = request.clone({ headers: request.headers.set('X-Brid-Connection', this.brandService.brandSettings.id) });
    }

    if (request.url.includes('/client-login') || 
        request.url.includes('/brand-settings') ||
        request.url.includes('/client-user')) {
      return next.handle(request);
    }

    if (this.authService.token && !this.authService.tokenExpired()) {
      return next.handle(this.addTokenHeader(request, this.authService.token));
    } else {
      this.authService.logout();
      return throwError(new Error());
    }
  }


  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer '.concat(token)) });
  }
}