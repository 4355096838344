import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap, shareReplay } from 'rxjs/operators';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';

export interface JWTPayload {
  token_type: string,
  exp: number,
  jti: string,
  user_id: string
}

export interface User {
  username: string,
  password: string
}

@Injectable()
export class AuthService {
  
  private apiUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  private setSession(authResult: any) {
    const payload = <JWTPayload> jwtDecode(authResult.token);
    const expiresAt = moment.unix(payload.exp);

    localStorage.setItem('client_tk', authResult.token);
    localStorage.setItem('client_tk_exp', JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem('client_uid', authResult.user.id)
  }

  get token() {
    return localStorage.getItem('client_tk');
  }

  get tokenExpiration() {
    return localStorage.getItem('client_tk_exp');
  }

  login(username: string, password: string) {
    return this.http.post(this.apiUrl.concat('/client-login'), { username, password }).
      pipe(
        tap((response: any) => {
          this.setSession(response);
        }),
        shareReplay(),
      );
  }

  logout() {
    this.router.navigate(['login']);
    
    localStorage.removeItem('client_tk');
    localStorage.removeItem('client_tk_exp');
    localStorage.removeItem('client_uid');
  }

  tokenExpired() {
    if (this.tokenExpiration) {
      return (Math.floor((new Date).getTime())) >= parseInt(this.tokenExpiration);
    }
    return true;
  }
  
  isLoggedIn() {
    if (!!this.token && !this.tokenExpired()) {
      return true;
    }
    return false;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  signup(data: any) {
    return this.http.post(this.apiUrl.concat('/client-user'), data)
      .pipe(
        tap((response: any) => {
          this.setSession(response);
        }),
        shareReplay(),
      );
  }
}
