import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard  {

  constructor(
    private authService: AuthService, 
    private router: Router
  ) { }

  canActivate() {
    if (this.authService.isLoggedIn()) {
      return true;
    } 
    else {
      this.authService.logout();
      this.router.navigate(['login']);

      return false;
    }
  }
}