import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[phone-number]'
})
export class PhoneNumberInputDirective {
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    
    let numbers = input.value.replace(/\D/g, "");
    if (numbers.length <=2) {
      numbers = '07';
    }
    if (numbers.length > 10) {
      numbers = numbers.substring(0, 10);
    }

    input.value = numbers;
    this.control.control?.setValue(numbers);
  }

  constructor(private control: NgControl) { 
  }

}
