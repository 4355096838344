import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BrandSettings } from '../models/Brand';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  private apiUrl: string = environment.apiUrl;

  public brandSettings!: BrandSettings;

  constructor(
    private http: HttpClient,
  ) { }

  getBrandSettings() {
    const url = window.location.hostname;
    return this.http.post(this.apiUrl.concat('/brand-settings'), { url }).pipe(tap((result: any) => this.brandSettings = result));
  }
}
